
<template>
  <!-- 添加组件弹框 -->
  <el-dialog
    :title="$t('vue_label_norm_addcomponent')"
    :visible="comAddassemblyTo"
    width="400px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :append-to-body="true"
  >
    <div class="addchoose">
      <el-button @click="fnfromreport">{{
        $t("vue_label_dashboard_generatefromreport")
      }}</el-button>
      <el-button @click="fnfromview">
        {{ $t("vue_label_dashboard_generatefromlistview") }}
      </el-button>
      <!-- <el-button type="primary" class="choose" @click="addDashBoard" disabled>从组件库选择</el-button> -->
    </div>
  </el-dialog>
</template>
      <script>
export default {
  props: {
    comAddassemblyTo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 报表
    fnfromreport() {
      this.$emit("fnclose");
      this.$emit("fnfromreport");
    },
    // 列表视图
    fnfromview() {
      this.$emit("fnclose");
      this.$emit("fnfromview");
    },
    // fnclose() {
    //   this.$emit("fnclose");
    // },
    handleClose() {
      this.$emit("fnclose");
    },
    // 添加组件
    // addDashBoard() {
    //   this.$emit("fnclose");
    //   this.$emit("addDashBoard");
    // },
  },
};
</script>
<style lang="scss" scoped>
.addchoose {
  display: flex;
  justify-content: center;
  align-items: center;
  .choose {
    width: 262px;
    margin-top: 12px;
    margin-left: 0;
  }
}
</style>
