<template>
  <!-- 穿透弹框 -->
  <el-dialog
    :title="$t('vue_label_dashboard_penetrationsetting')"
    :visible="isShowPierceAlert"
    :before-close="handleClose"
    :close-on-click-modal="false"
    width="425px"
  >
    <div class="table-name">
      <span>{{$t('vue_label_dashboard_penetrateto')}}</span>
      <div class="go-table">{{ reportName }}{{ throughType }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="pierceCancel">{{$t('button_partner_cancel')}}</el-button>
      <el-button type="primary" @click="pierceAffirm">{{$t('label.ems.confirm')}}</el-button>
    </span>
  </el-dialog>
  <!-- </div> -->
</template>
<script>
export default {
  props: {
    isShowPierceAlert: {
      type: Boolean,
      default: false,
    },
    reportName: {
      // 报表名称
      type: String,
      default: "",
    },
    throughType: {
      // 穿透类型
      type: String,
      default: "",
    },
  },
  methods: {
    pierceCancel() {
      this.$emit("pierceCancel");
    },
    pierceAffirm() {
      this.$emit("pierceAffirm");
    },
    handleClose() {
      this.$emit("pierceCancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.table-name {
  height: 28px;
  line-height: 28px;
  color: #333333;
}
.go-table {
  // width: 200px;
  // height: 26px;
  line-height: 26px;
  text-align: center;
  display: inline-block;
  padding: 0 5px;
  color: #333333;
  border-radius: 3px;
  border: 1px solid #e2e2e2;
  margin-left: 10px;
}
</style>