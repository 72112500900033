<template>
  <!-- 仪表板首页 -->
  <div class="body">
    <div class="box">
      <div
        :class="[isEditStatus && isShowSidebar ? 'opacity-style' : '']"
      ></div>
      <!-- 头部卡片 -->
      <Dashboard-head-card
        @addDashBoard="addDashBoard"
        @fnfromreport="fnfromreport"
        @fnfromview="fnfromview"
        :isEdit="isEdit"
        :isDelete="isDelete"
        :isEditStatus="isEditStatus"
        :dashboardName="dashboardName"
        :dashboardid="dashboardid"
        :isShowSidebar="isShowSidebar"
        @cancelDashBoard="cancelDashBoard"
        @editDashBoard="editDashBoard"
        @saveDashBoardLayout="saveDashBoardLayout"
        @setScreenCondition="setScreenCondition"
        @createDashBoard="createDashBoard"
        @delDashboard="delDashboard"
        @refreshEvent="refreshEvent"
        @screenFull="screenFull"
      />
      <div class="main-body">
        <!-- 左侧菜单 -->
        <Dashboard-index-left-menu
          v-show="isShowSidebar"
          class="left-manu"
          ref="DashboardIndexLeftMenuRef"
          @sidebarFlag="sidebarFlag"
          @getByDashboardCharts="getByDashboardCharts"
          @dashboardChartZero="dashboardChartZero"
          :dashboardid="dashboardid"
          :dashboardfolderid="dashboardfolderid"
        />
        <div class="sidebar" v-show="!isShowSidebar" @click="sidebarFlag()">
          <!-- 展开侧边栏 -->
          <el-tooltip effect="dark" :content="$t('c913')" placement="right">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-dashboard-unfold"></use>
            </svg>
          </el-tooltip>
        </div>
        <!-- 公共组件 -->
        <Common-index
          v-if="!noChart"
          :commonDataArr="commonDataArrs"
          class="right-main"
          :class="{ 'right-main_div': !isShowSidebar }"
          :isEdit="isEdit"
          @testCopy="testCopy"
          @fnchooseLinkage="fnchooseLinkage"
          @fnchoosepierce="fnchoosepierce"
          @fnchoosedelete="fnchoosedelete"
          @fnchooseEdit="fnchooseEdit"
          @addComponToKuOpen="addComponToKuOpen"
          :isEditStatus="isEditStatus"
          @locationLayout="watchLocationLayout"
          ref="CommonIndex"
        />
        <div v-if="noChart" class="nochartTitle">
          {{ $t("vue_label_SMS_nodata") }}
        </div>
        <!-- 添加到组件库 -->
        <Components-store-alert
          :isShowComponentsStoreAlert="isShowComponentsStoreAlert"
          @affirm="addCpmAffirm"
          @cancel="addCpmCancel"
        />
        <!-- 联动弹框 -->
        <ComLinkageAlert
          :DashboardDatas="DashboardDatas"
          :selectDashboardData="selectDashboardData"
          :isShowDashLinkageAlert="isShowDashLinkageAlert"
          @linkageAffirm="linkageAffirm"
          @linkageCancel="linkageCancel"
        />
        <!-- 穿透弹框 -->
        <ComPierceAlert
          :reportName="reportLabel"
          :throughType="throughType"
          :isShowPierceAlert="isShowPierceAlert"
          @pierceAffirm="pierceAffirm"
          @pierceCancel="pierceCancel"
        />
        <!-- 删除图表弹框 -->
        <ComdeleteAlert
          :type="$t('label.dashboard.component')"
          :name="chartName"
          :isShowDeleteAlert="isShowDeleteAlert"
          @deleteAffirm="deleteAffirm"
          @deleteCancel="deleteCancel"
        />
        <!-- 仪表板删除弹框 -->
        <Components-del
          :componentsDelTo="componentsDelTo"
          :type="$t('label.version.standard.dashboard')"
          :name="dashboardName"
          @ComponentsDelCancle="componentsDelCancle"
          @ComponentsDelConfirm="ComponentsDelConfirm"
        />
        <!-- 添加到组件库弹框 -->
        <AddComponToKu
          name="业务机会111"
          :isShowAddComponToKu="isShowAddComponToKu"
          @addAffirm="addAffirm"
          @addCancel="addCancel"
        />
        <!-- 添加到组件模板弹框 -->
        <ComponentEditor
          ref="ComponentEditorRef"
          :isShowComponentsEditorAlert="isShowComponentsEditorAlert"
          :sourceType="sourceType"
          :dashboardy="dashboardy"
          :dashboardid="dashboardid"
          :chartInfo="chartInfo"
          :linkageArr="linkageArr"
          @cancel="componentEditorCancel"
          @affirm="componentEditorAffirm"
          :watchLayoutArr="watchLayoutArr"
        />
        <!-- 设置筛选条件弹框 -->
        <RemittanceList
          :isShowRemittanceListAlert="isShowRemittanceListAlert"
          :dashboardid="dashboardid"
          @screenCancel="screenCancel"
          @screenApplication="screenApplication"
        />
        <!-- 添加新建仪表板 -->
        <DashboardNewBuild
          ref="DashboardNewBuildRef"
          :dashboardfolderid="
            chooseFolderid ? chooseFolderid : dashboardfolderid
          "
          :isShowCrateDashBoardAlert="isShowCrateDashBoardAlert"
          :dashboardInfos="dashboardInfos"
          @createDashBoardCancel="createDashBoardCancel"
          @createDashBoardSave="createDashBoardSave"
        />
        <!-- 图表全屏组件 -->
        <chartFullScreen
          :chartFullScreenTo="chartFullScreenTo"
          :DashboardDataFullScreen="DashboardDataFullScreen"
          @chartFullScreenClose="chartFullScreenClose"
        />
        <!-- 主页内容全屏弹框(整个仪表板全屏也可用) -->
        <homePageFullScreen
          v-if="homePageFullScreenTo"
          :homePageFullScreenTo="homePageFullScreenTo"
          @homePageFullScreenClose="theWholeFullFullScreenClose"
          :homePagetitle="`${dashboardName}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import homePageFullScreen from "@/components/FullScreen/homePageFullScreen"; // 主页内容全屏组件(整个仪表板全屏也可用)
import DashboardHeadCard from "./components/DashboardHeadCard";
import DashboardIndexLeftMenu from "./components/DashboardIndexLeftMenu";
import CommonIndex from "../../homePage/components/CommonIndex";
import ComLinkageAlert from "./components/ComLinkageAlert"; //联动弹窗
import ComPierceAlert from "./components/ComPierceAlert"; //穿透弹窗
import ComdeleteAlert from "./components/ComdeleteAlert"; //删除图表弹窗
import ComponentsDel from "./components/ComponentsDel"; // 删除仪表板弹框
import AddComponToKu from "./components/AddComponToKu"; //联动弹窗
import ComponentEditor from "./components/ComponentEditor";
import RemittanceList from "../../homePage/components/RemittanceList";
import DashboardNewBuild from "../../dashboardObject/dashBoardList/components/DashboardNewBuild"; //新建仪表板弹框
import ComponentsStoreAlert from "./components/ComponentsStoreAlert"; // 添加到组件库
import * as request from "../api.js";
import mixin from "@/mixin/homepageAndDashboardMixin.js";
import chartFullScreen from "@/components/FullScreen/chartFullScreen"; // 图表全屏组件

export default {
  components: {
    DashboardHeadCard,
    DashboardIndexLeftMenu,
    CommonIndex,
    ComLinkageAlert,
    ComPierceAlert,
    ComdeleteAlert,
    AddComponToKu,
    ComponentEditor,
    RemittanceList,
    DashboardNewBuild,
    ComponentsDel,
    ComponentsStoreAlert,
    chartFullScreen,
    homePageFullScreen,
  },
  mixins: [mixin],
  data() {
    return {
      // timer: null, //定时器
      homePageFullScreenTo: false, //整个仪表板的全屏
      dashboardName: "", //当前打开的仪表板名字
      dashboardfolderid: "", //仪表板文件夹id
      watchLayoutArr: [], //重新排序后的位置布局保存
      isEdit: false, //是否可编辑
      isDelete: false, //是否可删除
      isShowRemittanceListAlert: false, //是否显示筛选条件弹框
      isEditStatus: false, // 编辑状态
      isShowSidebar: true, //是否收起侧边栏
      noChart: false,
      chooseFolderid: "", //当前文件夹id
    };
  },
  created() {
    this.dashboardid = this.$route.params.dashboardid; // 接收的仪表板id
  },
  async mounted() {
    this.getDashboardList();
    await this.getChartsByDashboardId();
    // 一进来页面会清空数据，所以需要获取数据来计算位置
    this.getDashboardy();
    // 控制图表全屏展示
    this.$bus.$on("clickChartFullScreen", this.ebFn);
  },
  destroyed() {
    this.$bus.$off("clickChartFullScreen",this.ebFn);
  },
  methods: {
    ebFn(e){
      this.DashboardDataFullScreen = e;
      this.chartFullScreenTo = true;
    },
    // 新建弹框
    createDashBoard() {
      this.isShowCrateDashBoardAlert = true;
      this.$refs.DashboardNewBuildRef.chooseSelectFolder();
    },
    //使整个仪表板全屏
    screenFull() {
      this.homePageFullScreenTo = true;
      setTimeout(() => {
        this.$bus.$emit("screenFullWholeDashboardData", this.commonDataArrs);
      }, 0);
    },
    //全屏关闭
    theWholeFullFullScreenClose() {
      this.homePageFullScreenTo = false;
    },
    // 获取全部仪表板
    async getDashboardList() {
      let params = {
        name: "", //搜索内容
        dashboardfolderid: "",
        islightning: "true",
        page: "1",
        pageSize: "10000",
      };
      let {
        data: { listDashboard },
      } = await request.getDashboardList(params);
      if (listDashboard.length > 0) {
        // 根据传入的仪表板id筛选出对应的仪表板数据
        var dataObject = listDashboard.find(
          (item) => item.id === this.dashboardid
        );
      }
      this.dashboardfolderid = dataObject.dashboardfolderid; // 仪表板所在文件夹id
      this.dashboardName = dataObject.name; // 仪表板名称
      document.title = this.$setTitle(`${this.dashboardName}`);
      // 根据匹配上的文件夹id查询文件夹下的所有仪表板及所在文件夹名称
      this.$refs.DashboardIndexLeftMenuRef.getDashboardFolderList();
    },
    // 文件夹下没有仪表板进行提示。
    dashboardChartZero() {
      this.noChart = true;
    },
    // 切换仪表板获取对应仪表板下的所有图表
    getByDashboardCharts(item, isedit, isdelete) {
      if (item.length == 1) {
        this.dashboardid = item[0].id;
        this.dashboardName = item[0].name;
      } else {
        this.dashboardid = item.id;
        this.dashboardName = item.name;
      }
      this.noChart = false;
      this.chooseFolderid = this.$refs.DashboardIndexLeftMenuRef.chooseFolder;
      document.title = this.$setTitle(`${this.dashboardName}`);
      this.isEdit = isedit;
      this.isDelete = isdelete;
      this.getChartsByDashboardId();
    },
    // 重新排序后的位置布局保存
    watchLocationLayout(layoutArr) {
      this.watchLayoutArr = layoutArr;
    },
    /**
     * 保存当前页面布局请求
     */
    async saveDashBoardLayout() {
      let tmpLayoutArr = JSON.parse(JSON.stringify(this.watchLayoutArr));
      tmpLayoutArr.forEach((item) => {
        item.width = item.w;
        item.height = item.h;
        item.dashboardx = item.x;
        item.dashboardy = item.y;
      });
      let res = await request.updateChartsLocation(tmpLayoutArr);
      if (res.result) {
        this.$message.success(
          this.$i18n.t("pagecreator_layout_message_save_success")
        );
        this.isEditStatus = false;
        // 重新请求页面布局
        await this.getChartsByDashboardId();
        // 更改图表信息，将缓存数据更新
        this.commonDataArrs.map((item) => {
          this.$bus.$emit("getLinkagegetData", item);
        });
        // 重新排列之后需要更新所在仪表板宽高
        let ruleForm = this.$refs.DashboardIndexLeftMenuRef.dashboardList.find(
          (item) => item.id === this.$refs.DashboardIndexLeftMenuRef.chooseId
        );
        let params = {
          id: ruleForm.id,
          name: ruleForm.name,
          description: ruleForm.description,
          dashboardfolderid: ruleForm.dashboardfolderid,
          islightning: true,
          width: 12, // 仪表板的高度宽度固定为12
          height: this.dashboardy, // 仪表板的高度即为下次添加图表时的y轴
        };
        await request.updateDashboard(params);
      }
    },
    // 取消编辑状态
    cancelDashBoard() {
      this.isEditStatus = false;
      // 重新请求页面布局
      this.getChartsByDashboardId();
    },
    // 进入编辑状态
    editDashBoard() {
      this.isEditStatus = true;
    },
    // 显示删除弹框
    fnchoosedelete(id, name) {
      this.isShowDeleteAlert = true;
      this.chartId = id;
      this.chartName = name;
    },
    // 保存显示删除弹框
    async deleteAffirm() {
      let params = {
        id: this.chartId,
      };
      await request.deleteChart(params);
      this.isShowDeleteAlert = false;
      await this.getChartsByDashboardId();
      // 在删除图表之后会触发组件的destroyed钩子，会把该组件的所有引用里的$bus.$on事件销毁，所以需要遍历该仪表板下剩下的所有图表，手动添加上监听事件
      if (this.$refs.CommonIndex.$refs.DashboardCard.length > 0) {
        this.$refs.CommonIndex.$refs.DashboardCard.map((item) => {
          item.getLinkagegetDataMethod();
        });
      }
      // 图表删除成功之后需要更新所在仪表板宽高
      let ruleForm = this.$refs.DashboardIndexLeftMenuRef.dashboardList.find(
        (item) => item.id === this.$refs.DashboardIndexLeftMenuRef.chooseId
      );
      let params1 = {
        id: ruleForm.id,
        name: ruleForm.name,
        description: ruleForm.description,
        dashboardfolderid: ruleForm.dashboardfolderid,
        islightning: true,
        width: 12, // 仪表板的高度宽度固定为12
        height: this.dashboardy, // 仪表板的高度即为下次添加图表时的y轴
      };
      await request.updateDashboard(params1);
    },
    // 取消显示删除弹框
    deleteCancel() {
      this.isShowDeleteAlert = false;
    },
    // 仪表板主页新建仪表板成功之后重新获取仪表板列表
    createDashBoardSave() {
      this.$refs.DashboardIndexLeftMenuRef.getDashboardList();
    },
    // 全局刷新
    refreshEvent() {
      this.$bus.$emit("refreshOverallData");
    },
    //侧边栏收起和弹开
    sidebarFlag() {
      this.isShowSidebar = !this.isShowSidebar;
    },
  },
};
</script>

<style scoped lang="scss">
.body {
  // padding: 10px;
  font-size: 12px;
}
.box {
  position: relative;
  color: #080707 !important;
  display: flex;
  flex-direction: column;
}
.left-manu {
  width: 240px;
  height: calc(100vh - 140px);
  position: fixed;
  z-index: 100;
  background: #fafaf9;
  border: none !important;
  box-shadow: none !important;
}
.nochartTitle {
  margin-left: 240px;
  height: calc(100vh - 140px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f5e5e;
}
.sidebar {
  width: 20px;
  cursor: pointer;
  border-left: 0;
  display: flex;
  min-height: calc(100vh - 255px);
  justify-content: center;
  margin-top: 10px;
  border-radius: 3px;
  float: left;
  position: fixed;
  svg {
    align-self: center;
    font-size: 12px;
    position: fixed;
    top: 55vh;
    width: 50px;
    height: 50px;
  }
}
.right-main {
  flex: 1;
  margin: 10px 0px 10px 240px;
}
.right-main_div {
  margin: 12px 10px 10px 10px;
}

.opacity-style {
  position: fixed;
  top: 46px;
  left: 10px;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8) !important;
  opacity: 0.9;
  width: 281px;
  z-index: 999;
}
//滚动条
::v-deep ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  //滑块部分
  border-radius: 0;
  background: #c1c1c1 !important;
  -webkit-box-shadow: none !important;
}
::v-deep ::-webkit-scrollbar-track {
  //轨道部分
  border-radius: 0;
  background: #f1f1f1 !important;
  -webkit-box-shadow: none !important;
}
</style>