<template>
  <el-dialog
    :title="$t('vue_label_dashboard_linkagesettings')"
    :visible="isShowDashLinkageAlert"
    width="450px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="linkage-check" v-if="DashboardDatas.length > 0">
      <el-checkbox
        v-for="(item, index) in DashboardDatas"
        :key="index"
        :label="item.id"
        v-model="linkageCheck"
      >{{ item.name }}</el-checkbox>
    </div>
    <div class="title" v-show="DashboardDatas.length == 0">{{$t('vue_label_dashboard_nolinkagedashboard')}}</div>
    <div class="remarks" v-if="DashboardDatas.length > 0">{{$t('vue_label_dashboard_eachother')}}</div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="linkageCancel">{{$t('button_partner_cancel')}}</el-button>
      <el-button type="primary" @click="linkageAffirm">{{$t('label.ems.confirm')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShowDashLinkageAlert: {
      type: Boolean,
      default: false,
    },
    DashboardDatas: {
      // 同一数据源的报表列表
      type: Array,
      default: () => [],
    },
    selectDashboardData: {
      // 同一数据源的报表所选中的列表
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      linkageCheck: [], //复选框选中数据
    };
  },
  watch: {
    isShowDashLinkageAlert: function(){
      this.linkageCheck = this.selectDashboardData;
    },
  },
  methods: {
    // 弹框取消按钮
    linkageCancel() {
      this.$emit("linkageCancel");
    },
    // 弹框确认按钮
    linkageAffirm() {
      this.$emit("linkageAffirm", this.linkageCheck);
    },
    // 点击其他地方关闭弹框
    handleClose() {
      this.$emit("linkageCancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.linkage-check {
  padding-left: 24px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.title {
  text-align: center;
  margin: 20px 0;
}

.remarks {
  height: 30px;
  line-height: 30px;
  color: #666666;
  padding-left: 24px;
  margin-top: 6px;
}
</style>