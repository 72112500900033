<!-- 添加到组件库弹框 -->
<template>
  <el-dialog
    :title="$t('vue_label_report_addtocomponentlibrary')"
    :visible="isShowAddComponToKu"
    width="450px"
    :before-close="addCancel"
    :close-on-click-modal="false"
  >
    <div class="center_center">
      {{ $t("vue_label_report_addtocomponent", { name: name }) }}
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="addAffirm">{{
        $t("label_chatter_ok")
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    isShowAddComponToKu: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  methods: {
    addCancel() {
      this.$emit("addCancel");
    },
    addAffirm() {
      this.$emit("addAffirm");
    },
  },
};
</script>
<style lang="scss" scoped>
.center_center {
  color: #686868;
}
</style>