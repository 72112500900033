<!--
  添加到组件库之后编辑组件，二次确认弹框
-->
<template>
  <el-dialog
    :title="$t('vue_label_dashboard_editcomponent')"
    :visible="componentsEditTo"
    width="528px"
    :before-close="ComponentsEditCancle"
    :close-on-click-modal="false"
  >
    <div class="center_center">
      <div>
        {{
          $t("vue_label_dashboard_notice_editcertification", {
            name: "测试客户视图",
          })
        }}
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="ComponentsEditCancle">取消</el-button>
      <el-button type="primary" @click="ComponentsEditConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    componentsEditTo: {
      // 是否显示弹框
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 取消编辑
    ComponentsEditCancle() {
      this.$emit("ComponentsEditCancle");
    },
    // 确认编辑
    ComponentsEditConfirm() {
      this.$emit("ComponentsEditCancle");
    },
  },
};
</script>

<style lang="scss" scoped>
.center_center {
  div {
    color: #686868;
  }
}
</style>