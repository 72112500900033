<!--
  添加到组件库
-->
<template>
  <div>
    <el-dialog
      :title="'组件库'"
      :visible="isShowComponentsStoreAlert"
      width="640px"
      :before-close="cancel"
      :close-on-click-modal="false"
    >
      <div class="center_center">
        <!-- 搜索 -->
        <div class="content_search">
          <el-input
            v-model="input"
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
          ></el-input>
        </div>
        <!-- 组件选择部分 -->
        <div class="content">
          <!-- 11111 -->
          <div class="content_components">
            <!-- 每一个小块 -->
            <div class="componentsBox" v-for="i in 13" :key="i">
              <!-- 头 -->
              <div class="components_top">
                <div class="top_left">测试客户视图</div>
                <div class="top_right"></div>
              </div>
              <!-- 中 -->
              <div class="components_center">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-crossManyDiffBar"></use>
                </svg>
              </div>
              <!-- 尾 -->
              <div class="components_bottom">
                <div>创建人:CX</div>
                <div>创建时间:2020-08-05</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="affirm">确定</el-button>
      </span>
    </el-dialog>
    <!-- 仪表盘组件编辑弹框 -->
    <ComponentsEdit
      :componentsEditTo="componentsEditTo"
      @ComponentsEditCancle="componentsEditCancle"
    ></ComponentsEdit>
    <!-- 仪表盘里组件库的删除弹框 -->
    <ComponentsDel
      :componentsDelTo="componentsDelTo"
      type="组件"
      name="测试客户视图"
      @ComponentsDelCancle="componentsDelCancle"
    ></ComponentsDel>
  </div>
</template>

<script>
import ComponentsEdit from "../components/ComponentsEdit";
import ComponentsDel from "../components/ComponentsDel";
export default {
  components: {
    ComponentsEdit,
    ComponentsDel,
  },
  props: {
    isShowComponentsStoreAlert: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: "",
      componentsEditTo: false,
      componentsDelTo: false,
    };
  },
  methods: {
    //关闭组件弹框
    cancel() {
      this.$emit("cancel");
    },
    //组件确认
    affirm() {
      this.$emit("affirm");
    },
    //打开组件中编辑弹框
    // componentsEditOpen() {
    //   this.componentsEditTo = true;
    // },
    //点击取消组件中编辑弹框消失
    componentsEditCancle() {
      this.componentsEditTo = false;
    },
    //打开组件中删除弹框
    // componentsDelOpen() {
    //   this.componentsDelTo = true;
    // },
    //点击取消组件中删除弹框消失
    componentsDelCancle() {
      this.componentsDelTo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.center_center {
  // padding: 20px 38px 30px 38px;
  border-radius: 4px;
  color: #666666;
  // 搜索
  .content_search {
    ::v-deep .el-input__inner {
      outline: none !important;
      border: none !important;
    }
  }
  // 内容
  .content {
    border-top: 1px solid #d8d8d8;
    padding-right: 16px;
    width: 580px;
    height: 520px;
    overflow: auto;
    .content_components {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .componentsBox {
        width: 177px;
        height: 177px;
        padding: 12px;
        border: 1px solid #d8d8d8;
        border-radius: 4px;
        margin-top: 19px;
        // 上
        .components_top {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .top_left {
            color: #686868;
            font-weight: bold;
          }
          .top_right {
            img {
              vertical-align: middle;
              cursor: pointer;
            }
            img:first-child {
              margin-right: 2px;
            }
          }
        }
        // 中
        .components_center {
          padding: 10px 0;
        }
        // 下
        .components_bottom {
          color: #666666;
          div:last-child {
            padding-top: 4px;
          }
        }
      }
    }
  }
}

//滚动条样式
::-webkit-scrollbar {
  width: 10px;
  margin-left: 10px;
  background: #f3f6f9;
  border-radius: 2px;
  opacity: 0.6;
  border: 1px solid #dadee4;
}
::-webkit-scrollbar-thumb {
  width: 6px;
  // height: 120px;
  background: #888888;
  border-radius: 4px;
}
</style>