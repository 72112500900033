<!--
  仪表板首页-左侧菜单
-->
<template>
  <el-card class="box-card">
    <!-- 菜单头部 添加切换文件功能 -->
    <div slot="header" class="clearfix">
      <el-tooltip effect="dark" :content="$t('c914')"  placement="top-start">
        <div class="shrink">
          <svg class="icon" aria-hidden="true" @click="sidebarFlag">
            <use href="#icon-dashboard-fold"></use>
          </svg>
        </div>
      </el-tooltip>
      <el-select
        v-model="chooseFolder"
        @change="getDashboardList('change')"
      >
        <el-option
          v-for="item in allFolderList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <!-- <span>{{ folderName }}</span> -->
    </div>
    <!-- 列表item -->
    <div v-for="(item, index) in dashboardList" :key="index" class="menu-item">
      <div :class="item.id == chooseId ? 'active' : 'no-active'">
        <a
          @click.prevent="chooseDashboard(item)"
          :href="`/#/dashboardObject/dashboardIndex/${item.id}`"
          target="_blank"
        >
          {{ item.name }}</a
        >
      </div>
    </div>
  </el-card>
</template>

<script>
import * as request from "../../api.js";

export default {
  props: {
    dashboardfolderid: {
      type: String,
      default: "",
    },
    dashboardid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      allFolderList: [], //获取文件夹列表
      chooseFolder: "", //当前文件夹
      // folderName: "", //文件夹名称
      dashboardList: [], //仪表板列表
      chooseId: "", //当前选中的id
      isEdit: false, //是否可编辑
      isDelete: false, //是否可删除
    };
  },
  mounted() {
    this.chooseId = this.dashboardid;
  },
  methods: {
    //仪表板全屏
    // screenFull() {
    //   this.$emit('screenFull');
    // },
    /**
     * 父组件调用
     * 获取文件夹列表接口
     */
    async getDashboardFolderList() {
      var params = {
        searchKeyWord: "",
      };
      let res = await request.getDashboardFolderList(params);
      if (res.data && res.data.allFolderList.length > 0) {
        this.allFolderList = res.data.allFolderList;
        let FolderList = this.allFolderList.find((items) => {
          return items.id == this.dashboardfolderid;
        });
        if (FolderList) {
          this.isEdit = FolderList.isEdit;
          this.isDelete = FolderList.isDelete;
        } 
        // else {
        //   this.folderName = "";
        // }
        this.chooseFolder = FolderList.id;
        this.getDashboardList();
      }
    },
    // 获取仪表板列表
    async getDashboardList(value) {
      let params = {
        name: "", //搜索内容
        dashboardfolderid: this.chooseFolder,
        islightning: "true",
        page: "1",
        pageSize: "10000",
      };
      let data = await request.getDashboardList(params);
      this.dashboardList = data.data.listDashboard;
      let currentdashboard = this.dashboardList.filter(
        (item) => item.id == this.dashboardid
      );
      if (data.data.totalnum == 0) {
        this.$emit("dashboardChartZero");
      } else {
        if (value == "change") {
          this.chooseDashboard(this.dashboardList[0]);
        } else {
          this.$emit(
            "getByDashboardCharts",
            currentdashboard,
            this.isEdit,
            this.isDelete
          );
        }
      }
    },
    // 切换文件夹下的仪表板
    chooseDashboard(item) {
      this.chooseId = item.id;
      this.$emit("getByDashboardCharts", item, this.isEdit, this.isDelete);
    },
    sidebarFlag() {
      this.$emit("sidebarFlag");
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-card__header {
  border: none;
  padding: 18px 15px 10px;
}
.clearfix {
  zoom: 1;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
  ::v-deep .el-select {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-input__inner{
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-input__icon{
    height: 30px;
    line-height: 30px;
  }
  span {
    flex-grow: 1;
    color: #333;
    font-weight: bold;
    font-size: 14px;
  }
}
::v-deep .el-card__body {
  height: calc(100% - 200px);
  overflow-y: auto;
  padding: 0 10px;
}
.box-card {
  width: 240px;
  box-shadow: none;
  border-radius: 0;
}
.sidebarleft {
  display: inline-block;
  cursor: pointer;
}
.menu-item {
  padding: 8px 28px;
  cursor: pointer;
}
.shrink {
  margin-right: 5px;
  cursor: pointer;
  width: 18px;
  height: 18px;
  svg {
    width: 18px;
    height: 18px;
  }
}
.active {
  a {
    display: inline-block;
    width: 100%;
    color: #006dcc !important;
  }
}
::v-deep .no-active {
  color: #080707 !important;
  a {
    display: inline-block;
    width: 100%;
    color: #080707 !important;
  }
}
</style>