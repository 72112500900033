<!-- 
  仪表板主页头部卡片
-->
<template>
  <div class="DashboardHeadCard_div">
    <div class="box-card">
      <div class="header-bar">
        <div class="header-bar-left" @click="backToList" v-show="isShowSidebar" >
          <svg class="icon" aria-hidden="true" >
            <use href="#icon-leftBlack"></use>
          </svg>
          <span>{{ $t("c654") }}</span>
        </div>
        <div class="menubox_top">
          <strong class="menubox-l-name">{{ dashboardName }}</strong>
          <div class="currency">
            （{{ $t("label.currency") }}：{{ $store.getters.getCurrencyCode }}）
          </div>
        </div>
      </div>
      <!-- 底部菜单扩展 -->
      <div class="bottom-menu-extend">
        <!-- 左侧 -->
        <div class="bottom-menu-extend-left" v-show="isShowSidebar">
          <div class="go-back" @click="createDashBoard">
            <span>{{ $t("label.version.standard.dashboard") }}</span>
            <!-- plus -->
            <svg class="icon" aria-hidden="true">
              <use href="#icon-plus"></use>
            </svg>
          </div>
        </div>
        <!-- 右侧 -->
        <!-- 非编辑状态的样式（运行状态） -->
        <div class="bottom_menu_extend_right_run" v-if="!isEditStatus">
          <!-- 仪表板名称及币种类型 -->
          <!-- <div class="menubox_top">
          <strong class="menubox-l-name">{{ dashboardName }}</strong>
          <div>
            （{{ $t("label.currency") }}：{{ $store.getters.getCurrencyCode }}）
          </div>
        </div> -->
          <!-- 筛选条件选择及设置筛选条件、操作按钮 -->
          <div class="menubox_bottom">
            <div class="condition_div">
              <time-person-screen :dashboardid="dashboardid" />
              <div class="set_div" @click="setScreenCondition">
                {{ $t("vue_label_report_setdrill") }}
              </div>
            </div>
            <div class="operation_div">
              <!-- 全屏按钮 -->
              <svg
                class="icon"
                aria-hidden="true"
                v-show="!changeBlueYes"
                @mouseover="overChange"
              >
                <use href="#icon-fullScreenWhite"></use>
              </svg>
              <svg
                class="icon"
                aria-hidden="true"
                @click="screenFull"
                v-show="changeBlueYes"
                @mouseout="outChange"
              >
                <use href="#icon-fullScreenBlue"></use>
              </svg>
              <!-- 刷新 -->
              <i
                ref="loadimgbox"
                class="el-icon-refresh-right refresh-class"
                @click="refreshEvent"
              ></i>
              <el-button size="mini" v-if="isDelete" @click="delDashboard">{{
                $t("component_setup_tabs_label_delete")
              }}</el-button>
              <el-button
                size="mini"
                v-show="isEdit"
                @click="editDashBoard"
                class="edit-btn"
                >{{ $t("c568") }}
              </el-button>
              <el-button
                type="primary"
                v-show="isEdit"
                size="mini"
                @click="addComponentT"
                >{{ $t("vue_label_norm_addcomponent") }}</el-button
              >
            </div>
          </div>
        </div>
        <!-- 编辑状态的显示样式 -->
        <div class="bottom_menu_extend_right_edit" v-if="isEditStatus">
          <div class="menubox-l">
            <!-- <strong class="menubox-l-name">{{ dashboardName }}</strong>
          <div>
            （{{ $t("label.currency") }}：{{ $store.getters.getCurrencyCode }}）
          </div> -->
          </div>
          <div class="menubox-r">
            <el-button size="mini" @click="cancelDashBoard">{{
              $t("button_partner_cancel")
            }}</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="saveDashBoardLayout"
              >{{ $t("label.save") }}</el-button
            >
          </div>
        </div>
      </div>

      <!-- 添加组件的两个数据来源弹框 -->
      <com-addassembly-alert
        :comAddassemblyTo="comAddassemblyTo"
        @fnfromreport="fnfromreport"
        @fnfromview="fnfromview"
        @fnclose="fnclose"
        @addDashBoard="addDashBoard"
      />
    </div>
  </div>
</template>

<script>
// import ComponentEditor from "../components/ComponentEditor";
import ComAddassemblyAlert from "./ComAddassemblyAlert";
import * as types from "@/store/mutations-types";
import TimePersonScreen from "./TimePersonScreen"; // 时间人员筛选组件
import timePersonnelFiltrateMin from "@/mixin/timePersonnelFiltrate";

export default {
  components: {
    // ComponentEditor,
    ComAddassemblyAlert,
    TimePersonScreen,
  },
  props: {
    // selectOne: {
    //   type: Array,
    //   default: () => [],
    // },
    // selectTwo: {
    //   type: Array,
    //   default: () => [],
    // },
    dashboardName: {
      type: String,
      default: "",
    },
    dashboardid: {
      // 仪表板id
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    // 是否显示侧边栏
    isShowSidebar: {
      type: Boolean,
      default: false,
    },

    /**
     * 是否是编辑状态
     * 使用时需要传值
     */
    isEditStatus: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [timePersonnelFiltrateMin],
  data() {
    return {
      changeBlueYes: false,
      // componentEditorTo: false,
      comAddassemblyTo: false, //添加组件库弹框
      isAddComponent: true, //是否可以添加组件
    };
  },
  // watch: {
  //   isEdit: function (newvalue) {
  //     this.$emit('update:isEdit',newvalue)
  //   },
  //   isDelete: function (newvalue, ) {
  //     this.$emit('update:isDelete',newvalue)
  //   },
  // },
  mounted() {
    document.title = this.$setTitle(this.dashboardName);
    this.$store.commit(types.SET_TAB_NAME, this.dashboardName);
    // 仪表板内图表是否可以继续添加，上限15个。
    this.$bus.$on("sendComponentLength", this.ebFn);
  },
  destroyed() {
    this.$bus.$off("sendComponentLength", this.ebFn);
  },
  methods: {
    ebFn(e){
      this.isAddComponent = e;
    },
    overChange() {
      this.changeBlueYes = true;
    },
    outChange() {
      this.changeBlueYes = false;
    },
    //点击全屏按钮
    screenFull() {
      this.$emit("screenFull");
      setTimeout(() => {
        this.$bus.$emit("letScreenSuccess");
      }, 0);
    },
    // 回到仪表板列表
    backToList() {
      this.$router.push({
        path: "/dashboardObject/dashBoardList",
        query: {},
      });
    },
    // 新建仪表板
    createDashBoard() {
      this.$emit("createDashBoard");
    },
    //'删除仪表盘'弹框打开
    delDashboard() {
      this.$emit("delDashboard");
    },
    //设置筛选条件弹框
    setScreenCondition() {
      this.$emit("setScreenCondition");
    },
    // 添加组件弹框
    addDashBoard() {
      this.$emit("addDashBoard");
    },
    // 添加组件弹框从报表添加
    fnfromreport() {
      this.$emit("fnfromreport");
    },
    //feng-添加组件
    addComponentT() {
      if (this.isAddComponent) {
        this.comAddassemblyTo = true;
      } else {
        this.$message.warning(
          this.$i18n.t("label.componenteditor.Limit.number.components")
        );
      }
    },
    // 添加组件弹框从视图添加
    fnfromview() {
      this.$emit("fnfromview");
    },
    // 添加组件弹框从组件库添加
    // fnfromassembly() {
    //   this.comAddassemblyTo = false;
    // },
    //关闭添加组件弹框
    fnclose() {
      this.comAddassemblyTo = false;
    },
    // 编辑
    editDashBoard() {
      this.$emit("editDashBoard");
    },
    // 保存整个页面按钮
    saveDashBoardLayout() {
      this.$emit("saveDashBoardLayout");
    },
    // 取消编辑状态
    cancelDashBoard() {
      this.$emit("cancelDashBoard");
    },
    // 刷新
    refreshEvent() {
      this.$refs.loadimgbox.classList.add("loadimg");
      this.$emit("refreshEvent");
      setTimeout(() => {
        this.$refs.loadimgbox.classList.remove("loadimg");
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.DashboardHeadCard_div {
  background: #FAFAF9;
  position: sticky;
  top: 0.1px;
  left: 10px;
  right: 10px;
  z-index: 100;
  .box-card {
    // margin-top: 10px;
  }
}
//刷新
@keyframes loadimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadimg {
  animation: loadimg 2s linear infinite;
}
.header-bar {
  width: 100%;
  // height: 59px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  // margin-bottom: 10px;
  background: #fff;
  display: flex;
  // padding: 15px 0 5px 20px;
  vertical-align: middle;
  align-items: center;
  .header-bar-left {
    font-size: 12px;
    padding: 18px 0 11px 15px;
    background: #FAFAF9;
    width: 240px;
    span{
      margin-left:10px;
    }
  }
  .header-bar-left:hover{
    cursor: pointer;
  }
  .menubox_top {
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 16px 0 9px 0px;
    .menubox-l-name {
      font-size: 16px;
      color: #222222;
      margin-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .currency{
      font-size: 16px;
    }
  }
}
.dashboard-create {
  // color: #888888;
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
  font-size: 16px;
}
// 时间筛选
.chooseTimeBox {
  width: 120px;
  ::v-deep .el-input,
  ::v-deep .el-select,
  ::v-deep .el-input__inner,
  ::v-deep .el-input__icon {
    height: 24px;
    line-height: 24px;
  }
}
// .block {
//   ::v-deep .el-input__inner {
//     width: 330px;
//     height: 24px;
//     line-height: 24px;
//   }
//   ::v-deep .el-date-editor,
//   ::v-deep .el-range__icon,
//   ::v-deep .el-date-editor,
//   ::v-deep .el-range-separator {
//     height: 24px;
//     line-height: 24px;
//   }
// }

::v-deep .el-range-editor,
::v-deep .el-input__inner {
  padding: 0;
}

/* 底部菜单扩展 */
.bottom-menu-extend {
  display: flex;
  width: 100%;
  // height: 47px;
  .bottom-menu-extend-left {
    width: 208px;
    margin:0 16px;
    background: #FAFAF9;
    display: flex;
    // align-items: center;
    border-bottom: 1px solid #E1E1E1;
    .go-back {
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      height: 20px;
      vertical-align: middle;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin: 8px 0 0 22px;
      svg {
        width: 15px;
        height: 15px;
        margin:2px 0 0 5px;
      }
    }
  }
  .bottom_menu_extend_right_run {
    width: calc(100% - 240px);
    padding: 0 10px 8px 10px;
    flex: 1;
    background: #ffffff;

    .menubox_bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .condition_div {
        display: flex;
        align-items: center;
        .set_div {
          color: #006dcc;
          cursor: pointer;
        }
      }
      .operation_div {
        display: flex;
        align-items: center;
        i {
          font-size: 14px;
          margin: 0 12px;
        }
        svg {
          width: 15px;
          height: 15px;
          cursor: pointer;
        }
        // // 全屏按钮
        // .el-icon-rank {
        //   font-size: 18px;
        //   transform: rotate(45deg);
        //   color: #777;
        //   cursor: pointer;
        //   vertical-align: -2px;
        // }
        .refresh-class {
          cursor: pointer;
        }
      }
    }
  }
  .bottom_menu_extend_right_edit {
    width: calc(100% - 240px);
    height: 40px;
    padding: 0 20px 10px 20px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    .menubox-l {
      display: flex;
      align-items: center;
      .menubox-l-name {
        font-size: 16px;
        color: #222222;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .menubox-r {
      display: flex;
      align-items: center;
    }
  }
}
/* 泡泡样式 */
.popo-ul {
  margin-bottom: 0px;
  li {
    height: 22px !important;
    line-height: 22px !important;
    padding: 0 10px;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover {
      background-color: #006dcc;
      color: #fff;
    }
  }
}
</style>